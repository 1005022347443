import React from 'react';
import clsx from 'clsx';
import Balancer from 'react-wrap-balancer';

// Wrap Balancer to remove type errors
const BalancerWrapper = props => <Balancer {...props} />;

// Util helper to convert new lines to <br /> tags
const convertNewLines = text =>
  text.split('\n').map((line, i) => (
    <span key={i}>
      {line}
      <br />
    </span>
  ));

export function ChatLine({ who = 'bot', message }) {
  if (!message) {
    return null;
  }
  const formattedMessage = convertNewLines(message);

  return (
    <div className="mb-5 rounded-lg bg-white px-4 py-5 sm:px-6">
      <div className="flex space-x-3">
        <div className="flex-1 gap-4">
          <p className="font-large text-xxl text-gray-900">
            <a href="#" className="hover:underline">
              {who === 'bot' ? 'bot' : 'user'}
            </a>
          </p>
          <p className={clsx(
            'text ',
            who === 'bot' ? 'font-semibold text-black ' : 'text-gray-500'
          )}>
            <BalancerWrapper>
              {formattedMessage}
            </BalancerWrapper>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ChatLine;
