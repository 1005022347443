import { useEffect, useState } from 'react'
import {  ChatLine } from './ChatLine'
import LoadingChatLine from './LoadingChatLine';
import { useCookies } from 'react-cookie'
import { collection, addDoc, serverTimestamp, query, orderBy,  onSnapshot } from "firebase/firestore";
import {db, auth} from "../../firebase";
import firebase from 'firebase/compat/app';

const COOKIE_NAME = 'tofa'
//const SERVER_URL = 'http://localhost:3005/stream'; 
//const SERVER_URL = 'http://localhost:3005/story'; 
const SERVER_URL = 'https://www.backend.talesofdesire.com/story';
//const SERVER_URL = 'https://www.backend.talesofdesire.com/stream';


// default first message to display in UI (not necessary to define the prompt)
export const initialMessages = [
	{
	  who: 'bot',
	  message: '',
	},
]
const InputMessage = ({ sendMessage, onSubmit }) => {
	const [voiceInput, setVoiceInput] = useState('');
	const [recognition, setRecognition] = useState(null);
	const [listening, setListening] = useState(false);
      
	useEffect(() => {
	  if (!recognition) {
	    const newRecognition = new window.webkitSpeechRecognition();
	    newRecognition.lang = 'en-US';
      
	    newRecognition.onresult = (event) => {
	      const transcript = event.results[0][0].transcript;
	      setVoiceInput(transcript);
	    };
      
	    newRecognition.onend = () => {
	      setListening(false);
	    };
      
	    setRecognition(newRecognition);
	  }
	}, [recognition]);
      
	const startRecognition = () => {
	  if (recognition && !listening) {
	    recognition.start();
	    setListening(true);
	  }
	};
      
	const stopRecognition = () => {
	  if (recognition && listening) {
	    recognition.stop();
	    setListening(false);
	  }
	};
      
	const handleVoiceRecognition = () => {
	  if (listening) {
	    stopRecognition();
	  } else {
	    startRecognition();
	  }
	};
      
	const handleSendMessage = () => {
	  if (voiceInput.trim() !== '') {
	    sendMessage(voiceInput);
	    setVoiceInput('');
	  }
	};
      
	return (
		<div className="mt-6 flex clear-both">
			<textarea
			aria-label="chat input"
			required
			rows="1"
			className="flex-auto resize-none appearance-none rounded-md bg-transparent border px-3 py-[calc(theme(spacing.2)-1px)] placeholder:text-black focus:outline-none"
			value={listening ? ' listening...' : voiceInput}
			onChange={(e) => setVoiceInput(e.target.value)}
			style={{ overflow: 'hidden' }}
			onInput={(e) => {
				e.target.style.height = 'auto';
				e.target.style.height = `${e.target.scrollHeight}px`;
			}}
			/>
			<button
			type="submit"
			className="ml-4 border-2 cursor-pointer rounded-lg w-32 flex flex-row items-center justify-center pt-1.5 pb-1.5"
			onClick={handleSendMessage}
			>
			Ask
			</button>
			<button
			type="button"
			className="ml-4 border-2 cursor-pointer rounded-lg w-32 flex flex-row items-center justify-center pt-1.5 pb-1.5"
			onClick={handleVoiceRecognition}
			>
			{listening ? 'Stop Voice' : 'Voice'}
			</button>
		</div>
	);
      };

const Layout = ({ id }) => {
	const [messages, setMessages] = useState(initialMessages)
	const [loading, setLoading] = useState(false)
	const [cookie, setCookie] = useCookies([COOKIE_NAME])
	const [lastBotMessageIndex, setLastBotMessageIndex] = useState(null)

	useEffect(() => {
		if (!cookie[COOKIE_NAME]) {
		// generate a semi random short id
		const randomId = Math.random().toString(36).substring(7)
			setCookie(COOKIE_NAME, randomId)
		}
	}, [cookie, setCookie])

	useEffect(() => {
		const chatMessagesRef = id ? collection(db, `chats/${id}/messages`) : null;

		const unsubscribe = chatMessagesRef ? onSnapshot(
		  	query(chatMessagesRef, orderBy('timestamp')), // Order by timestamp or any relevant field
		  	(snapshot) => {
		   	 	const allMessages = [];
	      		
				snapshot.forEach((doc) => {
					const messageData = doc.data();
					allMessages.push(messageData);
		   		});
				setMessages(allMessages); // Set all messages
		  	}
		) : null;
	      
		return () => unsubscribe && unsubscribe();
	}, [id]);

	const generateArgsAsString = (userMessage, userId, chatHistory, chatId, accessToken) => {
		return `userMessage=${encodeURIComponent(userMessage)}&
				userId=${encodeURIComponent(userId)}&
				chatHistory=${encodeURIComponent(chatHistory)}&
				chatId=${encodeURIComponent(chatId)}&
				accessToken=${encodeURIComponent(accessToken)}`;
	};
  	// send message to API endpoint
	const sendMessage = async (userMessage) => {
		setLoading(true); // Initiate loading state
	
		const newMessages = [
			...messages,
			{ message: userMessage, who: 'user' },
		];
		setMessages(newMessages);
	
		const chatMessagesRef = id ? collection(db, `chats/${id}/messages`) : null;

		await addDoc(chatMessagesRef, {
			message: userMessage,
			who: 'user',
			timestamp: new Date(),
			authorId: auth.currentUser.uid,
		});
		if (SERVER_URL.includes("stream")) {
			console.log("************************** USE SSE **************************");
			try {
				const idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
	
				var fullResponse = '';
				const streamURL = SERVER_URL + '?' + generateArgsAsString(
					userMessage,
					auth.currentUser.uid,
					newMessages,
					id,
					idToken);
				console.log(streamURL);
				const eventSource = new EventSource(streamURL);
				
				eventSource.onopen = function(event) {
					console.log('Stream Started');
				};
	
				eventSource.onmessage = function (e) {
					console.log("Stream Delta Event: ", e);
					const eventMessage = JSON.parse(e.data);
					const handleAsyncOperations = async (fullResponse) => {
						const botMessageDocRef = await addDoc(chatMessagesRef, {
							message: fullResponse.trim(),
							who: 'bot',
							timestamp: new Date(),
							parentId: chatMessagesRef.id,
						});
						console.log("Bot response saved with ID: ", botMessageDocRef.id);
					}
					if (eventMessage.endOfStream) {
						console.log("End of Stream Message Received (((((((((((((((((((");
						console.log("Stream closed");
						setMessages(prev => [...prev, { message: fullResponse.trim(), who: 'bot' }]);
						
						// Optionally save the complete bot response to Firestore here
						handleAsyncOperations(fullResponse);
						setLoading(false);
						// eventSource.close();
					} else {
						 // This converts the JSON string in e.data to an object
						fullResponse += eventMessage.delta; ;
					}
				};
	
				eventSource.onerror = function(event) {
					console.error('Stream Error occurred: ', event);
				};
	
				eventSource.onclose = async () => {
					console.log("Stream closed");
					setMessages(prev => [...prev, { message: fullResponse.trim(), who: 'bot' }]);
			
					// Optionally save the complete bot response to Firestore here
					const botMessageDocRef = await addDoc(chatMessagesRef, {
						message: fullResponse.trim(),
						who: 'bot',
						timestamp: new Date(),
						parentId: chatMessagesRef.id,
					});
					console.log("Bot response saved with ID: ", botMessageDocRef.id);
					setLoading(false);
				};
			} catch (error) {
				console.error('Error during sendMessage operation:', error);
			} finally {
				
			}
		} else {
			console.log("************************** USE CLIENT SIDE STREAMING **************************");
			try {
				const idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
				const response = await fetch(SERVER_URL, { //http://localhost:3002/story
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ 
						userMessage: userMessage, 
						userId: auth.currentUser.uid, 
						chatHistory: newMessages,
						chatId: id,
						accessToken: idToken 
					}),
				});
				var fullResponse = '';
				const data = await response.json();
				console.log(data);
				console.log(data.url);
	
				const source = new EventSource(data.url);
				
				source.addEventListener("output", async(e) => {
					console.log("output", e);
					const botResponse = e.data.trim();
					fullResponse = fullResponse + botResponse;
				});
				
				source.addEventListener("onM", async(e) => {
					console.log("output", e);
					const botResponse = e.data.trim();
					fullResponse = fullResponse + botResponse;
				});

				
				source.addEventListener("error", (e) => {
					console.error("error", e);
					if (source.readyState === EventSource.CLOSED) {
						console.error("Connection was closed", e);
					} else {
						console.error("An error occurred", e);
					}
					source.close();
				});

				source.addEventListener("open", (e) => {
					console.log("Connection opened", e);
				});
				
				source.addEventListener("done", async(e) => {
					source.close();
					console.log("done", e);

					const newMessages = [
						...messages,
						{ message: fullResponse, who: 'bot' },
					];
					setMessages(newMessages);

					setLoading(false); // Ensure loading state is always reset
	
					const chatMessagesRef = id ? collection(db, `chats/${id}/messages`) : null;
	
					await addDoc(chatMessagesRef, {
						message: userMessage,
						who: 'user',
						timestamp: new Date(),
						authorId: auth.currentUser.uid,
					});
			
					const botMessageDocRef = await addDoc(chatMessagesRef, {
						message: fullResponse,
						who: 'bot',
						timestamp: new Date(),
						parentId: chatMessagesRef.id,
					});
					console.log("Bot response saved with ID: ", botMessageDocRef.id);
				});
	
			} catch (error) {
				console.error('Error during sendMessage operation:', error);
			} finally {
				
			}
		}
		
	};	

	return (
		<div className="overflow-auto flex flex-col justify-between lg:w-[70%] h-screen lg:h-full  p-6">
			<div className="flex flex-col space-y-4">
				{messages.map(({ message, who }, index) => (
					<ChatLine key={index} who={who} message={message} />
				))}

				{loading && <LoadingChatLine />}
			</div>
			<div className="mt-4">	
				{messages.length < 2 && (
					<span className="mx-auto text-white clear-both">
						Type a message to start the conversation
					</span>
				)}
				<InputMessage sendMessage={sendMessage} />
			</div>	
		</div>
	)
}

export default Layout