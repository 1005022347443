import React from 'react';

export const LoadingChatLine = () => (
  <div className="flex min-w-full animate-pulse px-4 py-5 sm:px-6">
    <div className="flex flex-grow space-x-3">
      <div className="min-w-0 flex-1">
        <p className="font-large text-xxl text-white">
          <a href="#" className="hover:underline">
            Bot
          </a>
        </p>
        <div className="space-y-4 pt-4">
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-2 h-2 rounded bg-zinc-500"></div>
            <div className="col-span-1 h-2 rounded bg-zinc-500"></div>
          </div>
          <div className="h-2 rounded bg-zinc-500"></div>
          <p className="text-xl text-white mt-4">
            Hang tight! We're processing your request. This may take up to 30 seconds.
          </p>
          <p className="text-md italic text-gray-300">
            Anticipation is the essence of desire... Your tantalizing tale is being woven as we speak. Prepare to be enthralled. 🔥
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default LoadingChatLine;
