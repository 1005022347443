
import { Link } from 'react-router-dom';

const Home = () => {
	return (
		<>
			<div className="md:py-20 py-10">
				<div className=" md:p-20 p-10 md:space-y-[15rem] space-y-[10rem] max-w-7xl flex flex-col justify-center items-center mx-auto  text-black  dark:text-white">
					<div className='justify-center gap-8 flex flex-col items-center text-center'>
						<div>
							Unleash desires, one story at a time
						</div>
						<div className="md:text-8xl text-7xl text-center font-bold">
							Tales of Desire
						</div>
						<div className="text-xl">
							App that will turn your imagination into a tale
						</div>
						<div  className='flex flex-row items-center text-white dark:text-black font-medium  justify-center gap-2 border-2 dark:border-black border-white bg-black dark:bg-white cursor-pointer rounded-lg py-3 px-6  '>
							<Link to='/signup'>
								Get started
							</Link>			
						</div>
					</div>
					<div className='flex flex-col justify-center items-start md:w-4/5'>
						<div className='md:text-5xl text-4xl font-semibold'>
							Made from your Dreams
						</div>
						<div className='md:mt-12 md:text-xl mt-5 font-semibold dark:text-slate-500 text-slate-700 '>
						Turn your wildest imaginations into enthralling stories, bridging the gap between fantasy and narrative reality.
						</div>
						<div className='mt-5 space-y-5'>
							<div className='flex flex-row items-center gap-5 '>
								<img src="/images/1.svg" alt="1" className='rounded-2xl w-16' />
								<span className='font-medium text-lg'>
									Capture the essence of your dreams and spin them into vivid, captivating tales.
								</span>
							</div>
							<div className='flex flex-row items-center gap-5'>
								<img src="/images/2.svg" alt="1" className='rounded-2xl w-16' />
								<span className='font-medium text-lg'>
									Experiment with limitless themes and styles, from surreal fantasies to grounded, everyday stories.
								</span>
							</div>
							<div className='flex flex-row items-center gap-5'>
								<img src="/images/3.svg" alt="1" className='rounded-2xl w-16' />
								<span className='font-medium text-lg'>
									Foster creativity and storytelling prowess with a platform that transforms dreamscapes into written art.
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>	
		</>
	)
}

export default Home
